import TopbarUserInfo from "./TopbarUserInfo";
import TopbarTitle from "./TopbarTitle";

const Topbar = ({ logoutUser }) => {
  return (
    <div className="Topbar">
      <TopbarTitle />
      <TopbarUserInfo logoutUser={logoutUser} />
    </div>
  );
};

export default Topbar;
