const reduxActions = {
  SET_BOARD_DATA: "SET_BOARD_DATA",
  SET_DISPLAY_NAME: "SET_DISPLAY_NAME",
  SET_LOGGED_IN: "SET_LOGGED_IN",
  SET_MODAL_IS_OPEN: "SET_MODAL_IS_OPEN",
  SET_SIGNUP_LOCK: "SET_SIGNUP_LOCK",
  SET_SIGNUP_FORM_STATE: "SET_SIGNUP_FORM_STATE",
};

export default reduxActions;
