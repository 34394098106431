import { useCallback } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import BoardArea from "./components/Board/BoardArea";
import Footer from "./components/Footer/Footer";
import SignUpModal from "./components/SignUpModal/SignUpModal";
import Topbar from "./components/Topbar/Topbar";
import api from "./functions/api";
import createReduxStore from "./reduxStore/createReduxStore";
import reduxActions from "./reduxStore/reduxActions";

const formInitialState = {
  username: "",
  password: "",
};

const { store, persistor } = createReduxStore();

const AppInner = () => {
  const dispatch = useDispatch();
  const selector = useSelector((x) => x);
  const {
    modalIsOpen,
    displayName,
    boardState,
    loggedIn,
    signUpFormState,
    signUpLock,
  } = selector;

  const setBoardState = useCallback(
    (x) => dispatch({ type: reduxActions.SET_BOARD_DATA, payload: x }),
    [dispatch]
  );
  const setDisplayName = useCallback(
    (x) => dispatch({ type: reduxActions.SET_DISPLAY_NAME, payload: x }),
    [dispatch]
  );

  const setLoggedIn = useCallback(
    (x) => dispatch({ type: reduxActions.SET_LOGGED_IN, payload: x }),
    [dispatch]
  );

  const setModalIsOpen = (x) =>
    dispatch({ type: reduxActions.SET_MODAL_IS_OPEN, payload: x });
  const setSignUpLock = (x) =>
    dispatch({ type: reduxActions.SET_SIGNUP_LOCK, payload: x });
  const setSignUpFormState = (x) =>
    dispatch({ type: reduxActions.SET_SIGNUP_FORM_STATE, payload: x });

  const getServerData = async () => {
    const raw = await api.getAllLists();
    const res = (await raw.json())?.lists;
    const listIdsOnServer = res.map((x) => x.listId);
    const oldLists = boardState?.filter((x) => !listIdsOnServer.includes(x.listId));

    const newState = res?.map((x) => ({ ...x, editingName: false }));
    if (newState) setBoardState([...newState, ...oldLists]);
    // sync locally created lists with server
    await api.batchCreateLists(oldLists);
  };

  const syncBoardData = async () => {
    await getServerData();
  };

  const authRequest = () => {
    setSignUpLock(true);
    api
      .authenticate(signUpFormState)
      .then((r) => {
        if (r.status === 200) {
          r.json().then((j) => {
            localStorage.setItem("token", j.token);
            setLoggedIn(true);
            setDisplayName(j.username);
            setSignUpLock(false);
            setModalIsOpen(false);
            setSignUpFormState(formInitialState);
            syncBoardData();
          });
        } else {
          r.json().then((t) => {
            alert(`Error ${r.status} : ${t.message}`);
            setSignUpLock(false);
          });
        }
      })
      .catch((e) => {
        setSignUpLock(false);
        alert(`Error : ${e.toString()}`);
      });
  };

  const logoutUser = () => {
    setLoggedIn(false);
    setDisplayName(null);
    setBoardState([]);
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="App">
      {modalIsOpen ? (
        <SignUpModal
          formState={signUpFormState}
          closeModal={() => setModalIsOpen(false)}
          signUpLock={signUpLock}
          onLogin={authRequest}
          onSignUp={authRequest}
          handleChange={(f, v) =>
            setSignUpFormState({ ...signUpFormState, [f]: v })
          }
        />
      ) : null}
      <Topbar
        openModal={() => setModalIsOpen(true)}
        loggedIn={loggedIn}
        displayName={displayName}
        logoutUser={logoutUser}
      />
      <BoardArea boardState={boardState} setBoardState={setBoardState} />
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppInner />
      </PersistGate>
    </Provider>
  );
};

export default App;
