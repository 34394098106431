import { legacy_createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createReduxStore = () => {
  let store = legacy_createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};

export default createReduxStore;
