import initialState from "./initialState";
import reduxActions from "./reduxActions";

const rootReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const {
    SET_BOARD_DATA,
    SET_DISPLAY_NAME,
    SET_MODAL_IS_OPEN,
    SET_SIGNUP_FORM_STATE,
    SET_SIGNUP_LOCK,
    SET_LOGGED_IN,
  } = reduxActions;

  switch (type) {
    case SET_BOARD_DATA:
      return { ...state, boardState: payload };
    case SET_LOGGED_IN:
      return { ...state, loggedIn: payload };
    case SET_DISPLAY_NAME:
      return { ...state, displayName: payload };
    case SET_MODAL_IS_OPEN:
      return { ...state, modalIsOpen: payload };
    case SET_SIGNUP_FORM_STATE:
      return { ...state, signUpFormState: payload };
    case SET_SIGNUP_LOCK:
      return { ...state, signUpLock: payload };
    default:
      return state;
  }
};

export default rootReducer;
