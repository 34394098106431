import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import reduxActions from "../../reduxStore/reduxActions";

const TopbarUserInfo = ({ logoutUser }) => {
  const dispatch = useDispatch();
  const openModal = () =>
    dispatch({ type: reduxActions.SET_MODAL_IS_OPEN, payload: true });
  const { loggedIn, displayName } = useSelector((x) => x);

  return (
    <div className="TopbarUserInfo" onClick={loggedIn ? null : openModal}>
      {loggedIn ? (
        <Fragment>
          Logged in as {displayName}
          <span onClick={logoutUser}> (Logout)</span>
        </Fragment>
      ) : (
        <Fragment>
          <span>Login</span> to sync your data
        </Fragment>
      )}
    </div>
  );
};

export default TopbarUserInfo;
