const formInitialState = {
  username: "",
  password: "",
};

const initialState = {
  modalIsOpen: false,
  displayName: "User",
  boardState: [],
  loggedIn: false,
  signUpFormState: formInitialState,
  signUpLock: false,
};

export default initialState;
